import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"

export const IndexPageTemplate = ({
  logo,
  heading,
  sectionIntro,
  section2,
  sectionBottom,
}) => {
  return (
    <Layout>
      <SEO title="Home" />
      {/* Move landing image up to cover nav (-73px) */}
      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center landing-image">
            <PreviewCompatibleImage
              imageInfo={{
                image: logo,
                alt: `Lightschools Logo`,
                className: "logo",
                loading: "eager",
              }}
            />
            <div className="landing-overlay"></div>
          </div>
        </Col>
      </Row>

      <Row className="w-100 h-100 d-flex flex-column align-items-center justify-content-start pt-5 section-2">
        <Col xs={10} className="mb-5">
          <h1
            className="mt-5 pb-5 landing-text"
            dangerouslySetInnerHTML={{ __html: heading }}
          ></h1>
        </Col>
      </Row>

      <Row className="d-flex flex-row align-items-center py-5 mx-0 section-because">
        <div className="overlay-top"></div>
        <Col>
          <h1 className="mt-5 pb-5 text-center">Because</h1>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>

      <Row className="py-5 d-flex flex-column mx-0 section-because-lower align-items-center">
        <Col xs={11} lg={8}>
          <p>{sectionIntro.top}</p>
        </Col>
        <Col xs={12} style={{ position: "relative" }}>
          <ul style={{ textAlign: "center", position: "relative" }}>
            <span className="bg-text">Because</span>
            <div
              dangerouslySetInnerHTML={{ __html: sectionIntro.middle }}
            ></div>
          </ul>
        </Col>
        <Col xs={11} lg={8} style={{ zIndex: 22 }}>
          <p>{sectionIntro.bottom}</p>
        </Col>
      </Row>
      <Row className="d-flex flex-row mx-0 section-mountain">
        <div className="overlay-top"></div>
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 1 }}>
          <p>{section2.top}</p>
        </Col>
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 5 }}>
          <p className="para-2">{section2.bottom}</p>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>
      <Row className="d-flex flex-column mx-0 section-bottom align-items-center pt-5">
        <Col xs={10} lg={8}>
          <p>{sectionBottom}</p>
        </Col>
      </Row>
    </Layout>
  )
}

const IndexPage = props => {
  const { frontmatter } = props.data.IndexPageTemplate
  console.log("INDEX PROPS", props)
  return (
    <IndexPageTemplate
      title={frontmatter.title}
      logo={frontmatter.logo}
      heading={frontmatter.heading}
      sectionIntro={frontmatter.sectionIntro}
      section2={frontmatter.section2}
      sectionBottom={frontmatter.sectionBottom}
    />
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($id: String!) {
    IndexPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        sectionIntro {
          top
          middle
          bottom
        }
        section2 {
          top
          bottom
        }
        sectionBottom
        logo {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
